import React from 'react'

export const Works = () => {
    return (
        <>

            {/* Facts */}
            <div id="facts" className="bg-black">

                {/* Row */}
                <div className="row no-margin no-padding">

                    {/* Column */}
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 center-col text-center no-padding image-bg-9 uk-flex uk-flex-middle">

                        {/* Image */}
                        <img className="padding-25px sm-text-center" src="images/backgrounds/bg-08.png" alt="" data-uk-scrollspy="cls:uk-animation-slide-bottom-medium" />

                    </div>
                    {/* End Column */}


                    {/* Column */}
                    <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 uk-flex uk-flex-middle">

                        {/* Container */}
                        <div className="container-small sm-container-spread md-padding-top-bottom-150px">

                            {/* Row */}
                            <div className="row">

                                {/* Column */}
                                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-left md-text-center md-no-margin-bottom">


                                    <h2 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Alguns Factos.</span></h2>

                                    <p className="no-margin text-gray-light">Atendemos a uma ampla base de clientes, desde pequenas empresas locais até grandes marcas nacionais e internacionais, abrangendo uma variedade de setores e indústrias.</p>

                                    <img className="margin-top-50px margin-bottom-50px width-60px opacity-8" src="images/separator-white.png" alt="" />

                                </div>
                                {/* End Column */}

                            </div>
                            {/* End Row */}



                            {/* Row */}
                            <div className="row" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > div; delay: 50; repeat: false">

                                {/* Column */}
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col md-margin-bottom-50px">

                                    {/* Counter */}
                                    <div className="counter text-left md-text-center">

                                        <span className="text-gray-light" data-uk-icon="icon: thumbnails; ratio: 2"></span>

                                        <h6 className="text-weight-700 margin-top-25px"><span className="counter-value text-gray-extra-dark bg-blue padding-left-10px padding-right-10px" data-count="450">0</span></h6>

                                        <p className="no-margin text-gray-light">Projectos.</p>

                                    </div>
                                    {/* End Counter */}

                                </div>
                                {/* End Column */}


                                {/* Column */}
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col md-margin-bottom-50px">

                                    {/* Counter */}
                                    <div className="counter text-left md-text-center">

                                        <span className="text-gray-light" data-uk-icon="icon: desktop; ratio: 2"></span>

                                        <h6 className="text-weight-700 margin-top-25px"><span className="counter-value text-gray-extra-dark bg-blue padding-left-10px padding-right-10px" data-count="789">0</span></h6>

                                        <p className="no-margin text-gray-light">Clientes.</p>

                                    </div>
                                    {/* End Counter */}

                                </div>
                                {/* End Column */}


                                {/* Column */}
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col sm-margin-bottom-50px">

                                    {/* Counter */}
                                    <div className="counter text-left md-text-center">

                                        <span className="text-gray-light" data-uk-icon="icon: happy; ratio: 2"></span>

                                        <h6 className="text-weight-700 margin-top-25px"><span className="counter-value text-gray-extra-dark bg-blue padding-left-10px padding-right-10px" data-count="723">0</span></h6>

                                        <p className="no-margin text-gray-light">Impressões.</p>

                                    </div>
                                    {/* End Counter */}

                                </div>
                                {/* End Column */}


                                {/* Column */}
                                <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col">

                                    {/* Counter */}
                                    <div className="counter text-left md-text-center">

                                        <span className="text-gray-light" data-uk-icon="icon: bookmark; ratio: 2"></span>

                                        <h6 className="text-weight-700 margin-top-25px"><span className="counter-value text-gray-extra-dark bg-blue padding-left-10px padding-right-10px" data-count="420">0</span></h6>

                                        <p className="no-margin text-gray-light">Produtos.</p>

                                    </div>
                                    {/* End Counter */}

                                </div>
                                {/* End Column */}

                            </div>
                            {/* End Row */}

                        </div>
                        {/* End Container */}

                    </div>
                    {/* End Column */}


                </div>
                {/* End Row */}

            </div>
            {/* End Facts */}


            {/* Call to Action */}
            <div id="shop" className="image-bg-10 overlay-black uk-position-relative uk-visible-toggle padding-25px" data-uk-slider="finite: true" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > ul ; delay: 50; repeat: false">

                {/* Slider Container */}
                <ul className="uk-slider-items uk-child-width-1-1@m uk-grid uk-grid-small" data-uk-height-viewport>


                    <li className="uk-transition-toggle uk-animation-toggle overflow-hidden">

                        {/* Slider Image */}
                        <img src="images/backgrounds/bg-10.jpg" alt="" data-uk-cover />


                        {/* Overlay */}
                        <div className="uk-position-cover">


                            {/* Overlay Content */}
                            <div className="uk-position-center text-center">

                                <div className="container">

                                    <h2 className="text-weight-700 margin-bottom-10px"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Nossa Loja Online</span></h2>


                                    {/* Container */}
                                    <div className="container sm-container-spread">

                                        {/* Row */}
                                        <div className="row" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > div; delay: 50; repeat: false">

                                            {/* Column */}
                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                                                {/* Blog */}
                                                <div className="blog-style-1 bg-white text-left">

                                                    <a href="#"><img src="images/portfolio/style-01/01.png" alt="" /></a>

                                                    {/* Pricing Table Title */}
                                                    <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">How to become an </a></p>


                                                    {/* Button */}
                                                    <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">comprar.</a>

                                                </div>
                                                {/* End Blog */}

                                            </div>
                                            {/* End Column */}

                                            {/* Column */}
                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                                                {/* Blog */}
                                                <div className="blog-style-1 bg-white text-left">

                                                    <a href="#"><img src="images/portfolio/style-01/02.png" alt="" /></a>

                                                    {/* Pricing Table Title */}
                                                    <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">What are the best </a></p>


                                                    {/* Button */}
                                                    <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">comprar.</a>

                                                </div>
                                                {/* End Blog */}

                                            </div>
                                            {/* End Column */}


                                            {/* Column */}
                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                                                {/* Blog */}
                                                <div className="blog-style-1 bg-white text-left">

                                                    <a href="#"><img src="images/portfolio/style-01/03.jpg" alt="" /></a>

                                                    {/* Pricing Table Title */}
                                                    <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">Graphic designers </a></p>


                                                    {/* Button */}
                                                    <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">comprar.</a>

                                                </div>
                                                {/* End Blog */}

                                            </div>
                                            {/* End Column */}


                                            {/* Column */}
                                            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                                                {/* Blog */}
                                                <div className="blog-style-1 bg-white text-left">

                                                    <a href="#"><img src="images/portfolio/style-01/04.jpg" alt="" /></a>

                                                    {/* Pricing Table Title */}
                                                    <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">What are the</a></p>



                                                    {/* Button */}
                                                    <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">comprar.</a>

                                                </div>
                                                {/* End Blog */}

                                            </div>
                                            {/* End Column */}


                                        </div>
                                        {/* End Row */}
                                        <h6 className="text-weight-700"><a className="bg-red text-white padding-left-10px padding-right-10px" href="https://shop.cnap.hiperhost.net/" >Comprar mais.</a></h6>
                                    </div>
                                </div>
                            </div>
                            {/* Overlay Content */}


                        </div>
                        {/* End Overlay */}

                    </li>

                </ul>
                {/* End Slider Container */}

            </div>
            {/* End Call to Action */}



        </>
    )
}



const Pricing = () => <>


    {/* Pricing Table */}
    <div id="pricing" className="padding-top-bottom-150px">


        {/* Container */}
        <div className="container-small sm-container-spread">

            {/* Row */}
            <div className="row">

                {/* Column */}
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-center md-no-margin-bottom">

                    <h6 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Our Pricing.</span></h6>

                    <p className="no-margin text-gray-regular">It has survived not onlya galley lorem ipsum of type and scrambled five centuries.</p>

                    <img className="margin-top-50px margin-bottom-50px width-60px opacity-8 margin-bottom-50px" src="images/separator.png" alt="" />


                </div>
                {/* End Column */}

            </div>
            {/* End Row */}

        </div>
        {/* End Container */}



        {/* Container */}
        <div className="container sm-container-spread">

            {/* Row */}
            <div className="row" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > div; delay: 50; repeat: false">

                {/* Column */}
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 center-col text-center md-margin-bottom-50px">

                    {/* Pricing Table */}
                    <div className="pricing-table-1 bg-gray-extra-light padding-50px text-left sm-padding-25px">

                        {/* Price */}
                        <h6 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">$299.</span></h6>

                        {/* Pricing Table Title */}
                        <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark no-margin-bottom"><span className="icon-gold margin-right-10px" data-uk-icon="icon: star; ratio: 1.3"></span> Basic.</p>

                        {/* Separator */}
                        <img className="margin-top-bottom-25px width-60px opacity-8" src="images/separator.png" alt="" />

                        {/* Pricing Table Features */}
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> One Website.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Unlimited Customization.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Market Analysis.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Facebook Ad Campaign.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> 24/7 Customer Support.</p>
                        {/* End Pricing Table Features */}

                        {/* Button */}
                        <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Sign Up.</a>

                    </div>
                    {/* End Pricing Table */}

                </div>
                {/* End Column */}

                {/* Column */}
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 center-col text-center md-margin-bottom-50px">

                    {/* Pricing Table */}
                    <div className="pricing-table-1 image-bg-6 overlay-white-dense padding-50px text-left sm-padding-25px">

                        {/* Price */}
                        <h6 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">$599.</span></h6>

                        {/* Pricing Table Title */}
                        <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark no-margin-bottom"><span className="icon-gold margin-right-10px" data-uk-icon="icon: settings; ratio: 1.3"></span> Premium.</p>

                        {/* Separator */}
                        <img className="margin-top-bottom-25px width-60px opacity-8" src="images/separator.png" alt="" />

                        {/* Pricing Table Features */}
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> One Website.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Unlimited Customization.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Market Analysis.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Facebook Ad Campaign.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> 24/7 Customer Support.</p>
                        {/* End Pricing Table Features */}

                        {/* Button */}
                        <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Sign Up.</a>

                    </div>
                    {/* End Pricing Table */}

                </div>
                {/* End Column */}


                {/* Column */}
                <div className="col-lg-4 col-md-12 col-sm-12 col-xs-12 center-col text-center">

                    {/* Pricing Table */}
                    <div className="pricing-table-1 bg-gray-extra-light padding-50px text-left sm-padding-25px">

                        {/* Price */}
                        <h6 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">$999.</span></h6>

                        {/* Pricing Table Title */}
                        <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark no-margin-bottom"><span className="icon-gold margin-right-10px" data-uk-icon="icon: bolt; ratio: 1.3"></span> Ultimate.</p>

                        {/* Separator */}
                        <img className="margin-top-bottom-25px width-60px opacity-8" src="images/separator.png" alt="" />

                        {/* Pricing Table Features */}
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> 05 Website.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Unlimited Customization.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Market Analysis.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> Facebook Ad Campaign.</p>
                        <p><span className="icon-gold margin-right-10px" data-uk-icon="icon: check; ratio: 1"></span> 24/7 Customer Support.</p>
                        {/* End Pricing Table Features */}

                        {/* Button */}
                        <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Sign Up.</a>

                    </div>
                    {/* End Pricing Table */}

                </div>
                {/* End Column */}

            </div>
            {/* End Row */}

        </div>
        {/* End Container */}

    </div>
    {/* End Pricing Table */}

</>