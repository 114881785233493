import React from 'react'
import { About } from './components/About'
import { Blogs } from './components/Blogs'
import { Clients } from './components/Clients'
import { Contact } from './components/Contact'
import { Footer } from './components/Footer'
import { Header } from './components/Header'

import { Services } from './components/Services'
import { Team } from './components/Team'
import { Works } from './components/Works'

export const HomePage = () => {
    return (<>
        {/*end header*/}
        {/*start hero section*/}

        
        <Header />

        {/*end hero section*/}
        {/*start feature box section*/}
        <About />
        {/*end parallax section*/}
        {/*start services section*/}
        <Services />
        {/*end accordion section*/}
        {/*start masonry portfolio section*/}
        <Works />

        {/*end parallax section*/}
        {/*start team section* /}
        <Team />
        {/*end team section*/}
        {/*start blog section*/}
        <Blogs />
        {/*end blog section*/}
        {/*start clients slider section * /}
        <Clients />
        {/*end review section*/}
        {/*start contact section*/}
        <Contact />
        {/*end features section*/}
        {/*start footer*/}
        <Footer />


    </>
    )
}
