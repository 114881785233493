import React from 'react'

export const Contact = () => {
    return (
        <>

            
		{/*Contact */}
		<div id="contact" className="bg-black">
		
			{/*Row */}
			<div className="row no-margin no-padding">
				
				{/*Column */}
				<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 center-col text-center no-padding image-bg-9 uk-flex uk-flex-middle">
				
					{/*Image */}
					<img className="padding-25px sm-text-center" src="images/backgrounds/bg-12.png" alt=""  data-uk-scrollspy="cls:uk-animation-slide-bottom-medium" />

				</div>
				{/*End Column */}
				
				
				{/*Column */}
				<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 uk-flex uk-flex-middle">
					
					{/*Container */}
					<div className="container-small sm-container-spread md-padding-top-bottom-150px">
					
						{/*Row */}
						<div className="row">
			
							{/*Column */}
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-left md-text-center md-no-margin-bottom">

								<h2 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Contacte-nos.</span></h2>
					
								<p className="no-margin text-gray-light">Entre em contato conosco para discutir as suas necessidades de impressão e personalização. Estamos aqui para ajudá-lo a transformar suas ideias em realidade!</p>
								
								<img className="margin-top-50px margin-bottom-50px width-60px opacity-8" src="images/separator-white.png" alt="" />

							</div>
							{/*End Column */}
						
						</div>
						{/*End Row */}
						
						
						
						{/*Row */}
						<div className="row">
						
							{/*Form Container */}
							<form className="contact-form" method="post" action="https://ela.bz/nave/contact.php">
							
								{/*Messages For The User */}
								<div className="messages"></div>

								{/*Form Controls */}
								<div className="controls">

									{/*Row */}
									<div className="row">
									
										{/*Column */}
										<div className="col-md-6 col-sm-12 col-xs-12">
										
											{/*Form Group */}
											<div className="form-group">

												{/*Input */}
												<input className="form-control form_name bg-transparent no-border-top no-border-left no-border-right border-color-gray-extra-dark border-2px no-margin-top padding-10px text-white text-weight-400" type="text" name="name"  placeholder="Primeiro Nome *" required data-error="Firstname is required."/>

												{/*Error Block */}
												<div className="help-block with-errors"></div>
											
											</div>
											{/*End Form Group */}
											
										</div>
										{/*End Column */}
										
					
										{/*Column */}
										<div className="col-md-6 col-sm-12 col-xs-12">
										
											{/*Form Group */}
											<div className="form-group">
											
												{/*Input */}
												<input className="form-control form_lastname bg-transparent no-border-top no-border-left no-border-right border-color-gray-extra-dark border-2px padding-10px text-white text-weight-400" type="text" name="name"  placeholder="Ultimo Nome *" required data-error="Lastname is required."/>
												
												{/*Error Block */}
												<div className="help-block with-errors"></div>
												
											</div>
											{/*End Form Group */}
											
										</div>
										{/*End Column */}

									</div>
									{/*End Row */}	


									
									{/*Row */}
									<div className="row">
									
										{/*Column */}
										<div className="col-md-6">
										
											{/*Form Group */}
											<div className="form-group">

												{/*Input */}
												<input className="form-control form_email bg-transparent no-border-top no-border-left no-border-right border-color-gray-extra-dark border-2px padding-10px text-white text-weight-400" type="text" name="name"  placeholder="E-mail *" required data-error="Email is required."/>
												
												{/*Error Block */}
												<div className="help-block with-errors"></div>
											
											</div>
											{/*End Form Group */}
											
										</div>
										{/*End Column */}
										
										
									  
										{/*Column */}
										<div className="col-md-6">
										
											{/*Form Group */}
											<div className="form-group">

												{/*Input */}
												<input className="form-control form_phone bg-transparent no-border-top no-border-left no-border-right border-color-gray-extra-dark border-2px padding-10px text-white text-weight-400" type="text" name="name"  placeholder="telefone *" required data-error="Phone is required."/>
												
												{/*Error Block */}
												<div className="help-block with-errors"></div>
												
											</div>
											{/*End Form Group */}
											
										</div>
										{/*End Column */}

									</div>
									{/*End Row */}	
									
									
									
									
									
									{/*Row */}
									<div className="row">
									
										{/*Column */}
										<div className="col-md-12">
										
											{/*Form Group */}
											<div className="form-group no-margin-bottom">
											
												{/*Input */}
												<textarea className="form-control form_message text-white no-margin-top no-border-top no-border-left no-border-right border-color-gray-extra-dark border-2px padding-20px bg-transparent text-gray-extra-dark" name="message" placeholder="Diz-nos em que podemos ajudar *" rows={4} required data-error="Please,leave us a message."></textarea>
												
												{/*Error Block */}
												<div className="help-block with-errors"></div>
												
											</div>
											{/*End Form Group */}
											
										</div>
										{/*End Column */}
									  
									  
										{/*Column */}
										<div className="col-md-12">
										
											{/*Input */}
											<input type="submit" className="btn-send btn btn-small btn-white md-margin-left-right-auto sm-display-table no-margin-bottom" value="Enviar" />
											
										</div>
										{/*End Column */}
										
									</div>
									{/*End Row */}
									
								</div>
								{/*End Form Controls */}
								
							</form>
							{/*End Form Container */}
							
						</div>
						{/*End Row */}

					</div>
					{/*End Container */}

				</div>
				{/*End Column */}
	
			</div>
			{/*End Row */}
			
		</div>
		{/*End Facts */}
        </>
    )
}
