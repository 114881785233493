
import './App.css';
import { HomePage } from './Pages/Home/Index';

function App() {
  return (
    <HomePage/>
  );
}

export default App;
