import React from 'react'

export const About = () => {
    return (
        <>
           
		{/*About */}
		<div id="about">
		
			{/*Row */}
			<div className="row no-margin no-padding">
				
	
				{/*Column */}
				<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 center-col text-center no-padding image-bg-7 uk-flex uk-flex-middle">
				
					{/*Image */}
					<img className="padding-25px" src="images/about/01.png" alt="" data-uk-scrollspy="cls:uk-animation-slide-bottom-medium" />

				</div>
				{/*End Column */}

				
				{/*Column */}
				<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 uk-flex uk-flex-middle">
					
					{/*Container */}
					<div className="container-small sm-container-spread md-padding-top-bottom-150px">
					
						{/*Row */}
						<div className="row">
			
							{/*Column */}
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-left md-text-center md-no-margin-bottom">

								<h2 className="text-weight-700 margin-bottom-10px"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px xs-line-height-40">Quem somos.</span></h2>
								<h6 className="text-weight-700"><span className="text-gray-extra-dark bg-blue text-white padding-left-10px padding-right-10px xs-line-height-40">Saiba mais sobre a grafica CNAP.</span></h6>

								<p className="no-margin">Além da excelente qualidade, nosso diferencial
                                    é a ﬂexibilidade nas tiragens, a rapidez, as con-
                                    dições de pagamento facilitadas e os preços
                                    competitivos.</p>
								
								<img className="margin-top-50px margin-bottom-50px width-60px opacity-8 margin-bottom-50px" src="images/separator.png" alt="" />
								
								<p className="text-weight-600 poppins text-extra-large text-gray-extra-dark">Queremos ser o seu parceiro de confiança para todas as suas necessidades de impressão e personalização,</p>
								
								<p className="no-margin">Somos uma gráﬁca em Angola e trabalhamos
                                    com as marcas mais conﬁaveis do mercado e
                                    pessoas singulares.</p>
					

								<p className="no-margin">Especializada na produção de impressos em
                                    geral. Destacamos a alta qualidade nos traba-
                                    lhos e a plena satisfação de nossos clientes.
                                    Essas medidas simples nos permitem encarar
                                    os desaﬁos de trabalhos cada vez mais comple-
                                    xos e soﬁsticados que o mercado de hoje nos
                                    impõe.</p>
					
							</div>
							{/*End Column */}
						
						</div>
						{/*End Row */}

					</div>
					{/*End Container */}

				</div>
				{/*End Column */}
				

			</div>
			{/*End Row */}
			
		</div>
		{/*End About */}
		

        </>
    )
}
