import React from 'react'

export const Services = () => {
    return (
        <>

<div id="service" className="md-bg-gray-extra-light">
		
			{/* Row */}
			<div className="row no-margin no-padding">
				
				
				{/* Column */}
				<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 uk-flex uk-flex-middle">
					
					{/* Container */}
					<div className="container-small sm-container-spread md-padding-top-bottom-150px">
					
						{/* Row */}
						<div className="row">
			
							{/* Column */}
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-left md-text-center md-no-margin-bottom">

								
								<p className="no-margin">Temos o compromisso de oferecer uma ampla gama de serviços gráficos de alta qualidade para atender às diversas necessidades dos nossos clientes. Nossa equipe experiente e equipamentos modernos nos capacitam a realizar os seguintes serviços</p>
								
								<img className="margin-top-50px margin-bottom-50px width-60px opacity-8" src="images/separator.png" alt="" />

							</div>
							{/* End Column */}
						
						</div>
						{/* End Row */}
						
						
						
						{/* Row */}
						<div className="row">
			
							{/* Column */}
							<div className="service col-md-6 col-sm-6 col-xs-12 margin-bottom-50px text-left sm-text-center">

								<p className="text-weight-600 poppins text-extra-large text-gray-extra-dark"><span className="icon-gold margin-right-10px" data-uk-icon="icon: microphone; ratio: 1.3"></span> Visão.</p>
								
								<p className="no-margin sm-text-center">Ser a marca líder que
                                    inspira conﬁança em
                                    quem produz e em
                                    quem utiliza.</p>
								
							</div>
							{/* End Column */}
							
							{/* Column */}
							<div className="service col-md-6 col-sm-6 col-xs-12 margin-bottom-50px text-left sm-text-center">

								<p className="text-weight-600 poppins text-extra-large text-gray-extra-dark"><span className="icon-gold margin-right-10px" data-uk-icon="icon: social; ratio: 1.3"></span> Missão.</p>
								
								<p className="no-margin">Ser um time obsti-
                                    nado em prever e
                                    atender as necessi-
                                    dades dos clientes e
                                    consumidores
                                    sempre, agregando
                                    valores.</p>
								
							</div>
							{/* End Column */}
							
							{/* Column */}
							<div className="service col-md-6 col-sm-6 col-xs-12 xs-margin-bottom-50px text-left sm-text-center">

								<p className="text-weight-600 poppins text-extra-large text-gray-extra-dark"><span className="icon-gold margin-right-10px" data-uk-icon="icon: location; ratio: 1.3"></span> VALORES.</p>
								
								<p className="no-margin">Respeito,
                                    Honestidade,
                                    Dedicação e
                                    Conﬁança.</p>
								
							</div>
							{/* End Column */}
							
							{/* Column * /}
							<div className="service col-md-6 col-sm-6 col-xs-12 text-left sm-text-center">

								<p className="text-weight-600 poppins text-extra-large text-gray-extra-dark"><span className="icon-gold margin-right-10px" data-uk-icon="icon: database; ratio: 1.3"></span> Analysis.</p>
								
								<p className="no-margin">Will be distracted by the readable content of a page.</p>
								
							</div>
							{/* End Column */}
							
						
						</div>
						{/* End Row */}

					</div>
					{/* End Container */}

				</div>
				{/* End Column */}
				

				{/* Column */}
				<div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 center-col text-center no-padding image-bg-11 uk-flex uk-flex-middle">
				
					{/* Image */}
					<img className="padding-25px sm-text-center" src="images/about/02.png" alt=""  data-uk-scrollspy="cls:uk-animation-slide-bottom-medium" />

				</div>
				{/* End Column */}


			</div>
			{/* End Row */}
			
		</div>

        <h6 className="d-block text-extra-dark-gray font-weight-600 alt-font mb-0 counter" data-to="350" data-speed="2000"></h6>
                  

		{/*Video Section */}
		<div id="video">
		
			{/*Grid 2 Columns */}
			<div className="uk-grid-collapse uk-child-width-1-2@l uk-child-width-1-1@s" data-uk-grid>
			
				
				{/* Left Column */}
				<div>
				
					<div className="image-bg-5" data-uk-height-viewport>
						
						<div className="video-icon text-center" data-uk-lightbox="animation: slide-bottom-medium"  data-uk-scrollspy="cls:uk-animation-slide-bottom-medium">
						
							<a className="sm-margin-left-right-auto text-black sm-display-table text-gold" href="https://www.youtube.com/watch?v=2eKrahtDk70"><span className="text-center" data-uk-icon="icon: play-circle; ratio: 3.5"></span></a>
						
						</div>
					
					</div>
					
				</div>
				{/* End Left Column */}
				
				
				{/*Right Column */}
				<div className="bg-black uk-flex uk-flex-middle" data-uk-height-viewport>
					
					{/*Container */}
					<div className="container-small sm-container-spread md-padding-top-bottom-150px">

						{/* Row */}
						<div className="row">
			
							{/* Column */}
							<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-left md-text-center md-no-margin-bottom">

								<h2 className="text-weight-700 margin-bottom-10px"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Saiba para quem trabalhamos.</span></h2>
								<h6 className="text-weight-700"><span className="text-gray-extra-dark bg-blue text-white padding-left-10px padding-right-10px xs-line-height-40">Saiba sobre os Nossos clientes.</span></h6>

								<p className="no-margin text-gray-light">Independentemente do tamanho ou setor de sua empresa, estamos comprometidos em fornecer serviços gráficos excepcionais que atendam às suas necessidades e excedam suas expectativas.</p>
								
								<img className="margin-top-50px margin-bottom-50px width-60px opacity-8 margin-bottom-" src="images/separator-white.png" alt="" />
								
								
								
								{/* Row */}
								<div className="row" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > div; delay: 50; repeat: false">
								
                                <div className="col-lg-4 col-md-6 col-sm-12 center-col margin-bottom-25px">
									
                                    <img className="opacity-8 sm-width-70" src="images/clients/01.png" alt="" />
                                
                                </div>
                                
                                <div className="col-lg-4 col-md-6 col-sm-12 center-col margin-bottom-25px">
                                
                                    <img className="opacity-8 sm-width-70" src="images/clients/02.png" alt="" />
                                
                                </div>
                                
                                
                                <div className="col-lg-4 col-md-6 col-sm-12 center-col margin-bottom-25px">
                                
                                    <img className="opacity-8 sm-width-70" src="images/clients/03.png" alt="" />
                                
                                </div>
                                
                                <div className="col-lg-4 col-md-6 col-sm-12 center-col md-margin-bottom-25px">
                                
                                    <img className="opacity-8 sm-width-70" src="images/clients/05.png" alt="" />
                                
                                </div>
                                
                                <div className="col-lg-4 col-md-6 col-sm-12 center-col sm-margin-bottom-25px">
                                
                                    <img className="opacity-8 sm-width-70" src="images/clients/06.png" alt="" />
                                
                                </div>
                                
                                <div className="col-lg-4 col-md-6 col-sm-12 center-col">
                                
                                    <img className="opacity-8 sm-width-70" src="images/clients/04.png" alt="" />
                                
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 center-col margin-bottom-25px">
									
										<img className="opacity-8 sm-width-70" src="images/clients/01.png" alt="" />
									
									</div>
									
									<div className="col-lg-4 col-md-6 col-sm-12 center-col margin-bottom-25px">
									
										<img className="opacity-8 sm-width-70" src="images/clients/02.png" alt="" />
									
									</div>
									
									
									<div className="col-lg-4 col-md-6 col-sm-12 center-col margin-bottom-25px">
									
										<img className="opacity-8 sm-width-70" src="images/clients/03.png" alt="" />
									
									</div>
									
									<div className="col-lg-4 col-md-6 col-sm-12 center-col md-margin-bottom-25px">
									
										<img className="opacity-8 sm-width-70" src="images/clients/05.png" alt="" />
									
									</div>
									
									<div className="col-lg-4 col-md-6 col-sm-12 center-col sm-margin-bottom-25px">
									
										<img className="opacity-8 sm-width-70" src="images/clients/06.png" alt="" />
									
									</div>
									
									<div className="col-lg-4 col-md-6 col-sm-12 center-col">
									
										<img className="opacity-8 sm-width-70" src="images/clients/04.png" alt="" />
									
									</div>
								
								</div>
								{/* End Row */}
								
								
							</div>
							{/* End Column */}
						
						</div>
						{/* End Row */}
						
					</div>
					{/* End Container */}
					
				</div>
				{/*End Right Column */}
				

			</div>
			{/* End Grid 2 Columns */}
		
		</div>
		{/* End Video Section */}
		

		{/* Portfolio */}
		<div id="portfolio" className="padding-top-150px">
			
			
			{/* Container */}
			<div className="container-small sm-container-spread">
			
				{/* Row */}
				<div className="row">
	
					{/* Column */}
					<div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-center md-no-margin-bottom">

						<h2 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Nosso Portfólio.</span></h2>
			
						<p className="no-margin text-gray-regular">Confira alguns dos nossos projetos recentes e veja como ajudamos nossos clientes a alcançar seus objetivos de impressão e personalização..</p>
						
						<img className="margin-top-50px margin-bottom-50px width-60px opacity-8 margin-bottom-50px" src="images/separator.png" alt="" />

					</div>
					{/* End Column */}
				
				</div>
				{/* End Row */}
				
			</div>
			{/* End Container */}
			
			
			{/* Container */}
			<div className="container-full">

				{/* Portfolio Filter */}
				<div data-uk-filter="target: .works">

					{/* Container */}
					<div className="container">
			
						{/* Filter */}
						<ul className="uk-subnav filter-style-1 margin-bottom-50px">
						
							<li className="uk-active"  data-uk-filter-control="filter: .images ,.videos, .projects"><a href="#" className="xs-margin-bottom-25px xs-no-margin-right">Todos.</a></li>
							<li data-uk-filter-control="filter: .images "><a href="#" className="xs-margin-bottom-25px xs-no-margin-right">GRÁFICOS.</a></li>
							<li data-uk-filter-control="filter: .videos"><a href="#" className="xs-margin-bottom-25px xs-no-margin-right">PERSONALIZAÇÃO.</a></li>
							<li data-uk-filter-control="filter: .projects"><a href="#" className="no-margin-right">PERSONALIZAÇÃO.</a></li>
							
						</ul>
						{/* End Filter */}
					
					</div>
					

					{/* Grid */}
					<div className="padding-15px">
						
						<div className="works uk-child-width-1-1@s uk-child-width-1-2@m uk-child-width-1-4@l uk-text-center uk-grid-small" data-uk-grid="masonry: true" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > div > a; delay: 50; repeat: false">
						
							{/* Work */}
							<div className="images" data-uk-lightbox="animation: fade">
							
								<a href="images/portfolio/style-01/03.jpg" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/03.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							{/* Work */}
							<div className="videos" data-uk-lightbox="animation: fade">
							
								<a href="https://www.youtube.com/watch?v=JFqiSr9A-Go" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/01.png" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							{/* Work */}
							<div className="projects">
							
								<a href="#" className="uk-inline-clip uk-transition-toggle" tabIndex={0}>
									
									{/* Image */}
									<img src="images/portfolio/style-01/02.png" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white"> Habits.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
								
								</a>
							
							</div>
							{/* End Work */}
							
							{/* Work */}
							<div className="images" data-uk-lightbox="animation: fade">
							
								<a href="images/portfolio/style-01/04.jpg" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/04.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							{/* Work */}
							<div className="images" data-uk-lightbox="animation: fade">
							
								<a href="images/portfolio/style-01/05.jpg" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/05.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							{/* Work */}
							<div className="videos" data-uk-lightbox="animation: fade">
							
								<a href="https://www.youtube.com/watch?v=JFqiSr9A-Go" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/06.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							{/* Work */}
							<div className="projects">
							
								<a href="#" className="uk-inline-clip uk-transition-toggle" tabIndex={0}>
									
									{/* Image */}
									<img src="images/portfolio/style-01/07.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white"> Habits.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
								
								</a>
							
							</div>
							{/* End Work */}
							
							{/* Work */}
							<div className="images" data-uk-lightbox="animation: fade">
							
								<a href="images/portfolio/style-01/08.jpg" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/08.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							
							{/* Work */}
							<div className="images" data-uk-lightbox="animation: fade">
							
								<a href="images/portfolio/style-01/09.jpg" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/09.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							{/* Work */}
							<div className="videos" data-uk-lightbox="animation: fade">
							
								<a href="https://www.youtube.com/watch?v=JFqiSr9A-Go" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/10.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}
							
							
							{/* Work */}
							<div className="projects">
							
								<a href="#" className="uk-inline-clip uk-transition-toggle" tabIndex={0}>
									
									{/* Image */}
									<img src="images/portfolio/style-01/11.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white"> Habits.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
								
								</a>
							
							</div>
							{/* End Work */}
							
							{/* Work */}
							<div className="images" data-uk-lightbox="animation: fade">
							
								<a href="images/portfolio/style-01/12.jpg" className="uk-inline-clip uk-transition-toggle" tabIndex={0} data-caption="This is a caption.">
									
									{/* Image */}
									<img src="images/portfolio/style-01/12.jpg" alt="" />
									
									{/* Hover */}
									<div className="uk-transition-fade uk-position-bottom bg-blue height-100">
									
										<div className="uk-position-center-left text-left padding-top-bottom-twenty-percent margin-left-ten-percent margin-right-eight-percent">
									
											<p className="text-weight-700 text-extra-large public-sans text-white">Bayblue.</p>
											
											<p className="no-margin text-gray-light">Will be distracted by the readable content of a page.</p>
		
										</div>
										
									</div>
									{/* End Hover */}
									
								</a>
							
							</div>
							{/* End Work */}

						</div>
						{/* End Grid */}
					
					</div>
					
				</div>
				{/* End Filter Container */}

			</div>
			{/* End Container */}

		</div>
		{/* End Portfolio */}
		
        
        </>
    )
}
