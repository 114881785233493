import React from 'react'

export const Blogs = () => {
    return (
        <>
            
            {/* Blog */}
            <div id="blog" className="padding-top-bottom-150px">


                {/* Container */}
                <div className="container-small sm-container-spread">

                    {/* Row */}
                    <div className="row">

                        {/* Column */}
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 center-col text-center md-no-margin-bottom">

                            <h2 className="text-weight-700"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Nossos Blogs.</span></h2>

                            <p className="no-margin text-gray-regular">Fique por dentro das últimas novidades e tendências no mundo da impressão e personalização. Nosso blog oferece artigos informativos, dicas úteis e inspiração para ajudá-lo a aproveitar ao máximo seus projetos de impressão e personalização. De dicas de design a informações sobre os mais recentes avanços tecnológicos na indústria gráfica, nosso blog é uma fonte valiosa de conhecimento para profissionais e entusiastas</p>

                            <img className="margin-top-50px margin-bottom-50px width-60px opacity-8 margin-bottom-50px" src="images/separator.png" alt="" />


                        </div>
                        {/* End Column */}

                    </div>
                    {/* End Row */}

                </div>
                {/* End Container */}



                {/* Container */}
                <div className="container sm-container-spread">

                    {/* Row */}
                    <div className="row" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > div; delay: 50; repeat: false">

                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                            {/* Blog */}
                            <div className="blog-style-1 text-left">

                                <a href="#"><img src="images/portfolio/style-01/01.png" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">How to become an app developer.</a></p>

                                <p>in <a href="#">Tutorials</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Joe Bodega</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}

                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                            {/* Blog */}
                            <div className="blog-style-1 bg-white text-left">

                                <a href="#"><img src="images/portfolio/style-01/02.png" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">What are the best treds of this decate.</a></p>

                                <p>in <a href="#">Lifestyle</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Mary Naomi</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}


                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                            {/* Blog */}
                            <div className="blog-style-1 bg-white text-left">

                                <a href="#"><img src="images/portfolio/style-01/03.jpg" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">Graphic designers gather up.</a></p>

                                <p>in <a href="#">Desigm</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Mary Naomi</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}


                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center margin-bottom-50px">

                            {/* Blog */}
                            <div className="blog-style-1 bg-white text-left">

                                <a href="#"><img src="images/portfolio/style-01/04.jpg" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">What are the best treds of this decate.</a></p>

                                <p>in <a href="#">Lifestyle</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Mary Naomi</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}


                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center md-margin-bottom-50px">

                            {/* Blog */}
                            <div className="blog-style-1 bg-white text-left">

                                <a href="#"><img src="images/portfolio/style-01/05.jpg" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">How to become an app developer.</a></p>

                                <p>in <a href="#">Tutorials</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Joe Bodega</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}

                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center md-margin-bottom-50px">

                            {/* Blog */}
                            <div className="blog-style-1 bg-white text-left">

                                <a href="#"><img src="images/portfolio/style-01/06.jpg" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">What are the best treds of this decate.</a></p>

                                <p>in <a href="#">Lifestyle</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Mary Naomi</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}


                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center sm-margin-bottom-50px">

                            {/* Blog */}
                            <div className="blog-style-1 bg-white text-left">

                                <a href="#"><img src="images/portfolio/style-01/07.jpg" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">Graphic designers gather up.</a></p>

                                <p>in <a href="#">Desigm</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Mary Naomi</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}


                        {/* Column */}
                        <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col text-center">

                            {/* Blog */}
                            <div className="blog-style-1 bg-white text-left">

                                <a href="#"><img src="images/portfolio/style-01/08.jpg" alt="" /></a>

                                {/* Pricing Table Title */}
                                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark margin-top-25px"><a href="#">What are the best treds of this decate.</a></p>

                                <p>in <a href="#">Lifestyle</a><span className="icon-gold margin-left-right-10px" data-uk-icon="icon: minus; ratio: 1"></span> by <a href="#">Mary Naomi</a></p>

                                {/* Button */}
                                <a className="btn btn-small btn-black sm-display-table no-margin-bottom" href="#">Read.</a>

                            </div>
                            {/* End Blog */}

                        </div>
                        {/* End Column */}

                    </div>
                    {/* End Row */}

                </div>
                {/* End Container */}

            </div>
            {/* End Blog */}
        </>
    )
}
