import React from 'react'

export const Footer = () => {
    return (

        <>

<footer className="bg-gray-extra-light">
		
			
        {/* Row */}
        <div className="row padding-left-right-twelve-percent padding-top-bottom-150px">
            
            {/* Column */}
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col md-margin-bottom-50px sm-text-center">
            
                {/* Logo Image */}
                <h6 className="text-weight-700"><span className="text-gray-extra-dark bg-gray padding-left-10px padding-right-10px">
                    <img src='images/logo.png' style={{height:80}}/>
                    .</span></h6>
                

                <p className="text-gray width-90 md-width-100 text-gray-dark">
                    
                Somos uma gráﬁca em Angola e trabalhamos
                com as marcas mais conﬁaveis do mercado e
                pessoas singulares.
</p>
                
                
                {/* Form Group */}
                <div className="form-group">
                
                    {/* Input */}
                    <input className="form-control form_lastname bg-transparent no-border-top no-border-left no-border-right border-color-gray-extra-dark border-2px padding-10px text-white text-weight-400 width-70 sm-width-100" type="text" name="name"  placeholder="Email" required data-error="Lastname is required."/>
                    
                </div>
                {/* End Form Group */}
                
                <a className="btn btn-small btn-transparent-black sm-display-table no-margin-bottom md-margin-left-right-auto" href="#">Subscrever</a>
        

            </div>
            {/* End Column */}
            
            
            {/* Column */}
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col md-margin-bottom-50px sm-text-center uk-fiex uk-flex-middle">
            
                {/* Title */}
                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark bg-gold">...</p>

                <div className="works uk-child-width-1-3@s uk-child-width-1-3@m uk-child-width-1-3@l uk-text-center uk-grid-small" data-uk-grid="masonry: true" data-uk-scrollspy="cls: uk-animation-slide-bottom-medium; target: > div > a; delay: 50; repeat: false">
                    
                    <a href="#">
                            
                            {/* Image */}
                            <img src="images/portfolio/style-01/01.png" alt="" />
                            
                    </a>
                        
                        
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/02.png" alt="" />
                        
                    </a>
                    
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/03.jpg" alt="" />
                        
                    </a>
                    
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/04.jpg" alt="" />
                        
                    </a>
                    
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/05.jpg" alt="" />
                        
                    </a>
                    
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/06.jpg" alt="" />
                        
                    </a>
                    
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/07.jpg" alt="" />
                        
                    </a>
                    
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/08.jpg" alt="" />
                        
                    </a>
                    
                    <a href="#">
                        
                        {/* Image */}
                        <img src="images/portfolio/style-01/09.jpg" alt="" />
                        
                    </a>

                </div>
                
            </div>
            {/* End Column */}
            

            
            
            {/* Column */}
            <div className="col-lg-2 col-md-6 col-sm-12 center-col col-xs-12 md-margin-bottom-50px text-center md-text-left sm-text-center">
            
                {/* Title */}
                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark">Redes Sociais.</p>
                

                {/* Links */}
                <ul className="list-unstyled no-margin">
                
                    <li className="no-margin"><p><a className="text-gray-extra-dark" href="#"><span className="facebook position-relative bottom-1 margin-right-10px" data-uk-icon="icon: facebook; ratio: 1"></span> Facebook</a></p></li>
                    <li className="no-margin"><p><a className="text-gray-extra-dark" href="#"><span className="instagram position-relative bottom-1 margin-right-10px" data-uk-icon="icon: instagram; ratio: 1"></span> Instagram</a></p></li>
                    <li className="no-margin"><p><a className="text-gray-extra-dark" href="#"><span className="pinterest position-relative bottom-1 margin-right-10px" data-uk-icon="icon: pinterest; ratio: 1"></span> Pinterest</a></p></li>
                    <li className="no-margin"><p><a className="text-gray-extra-dark" href="#"><span className="behance position-relative bottom-1 margin-right-10px" data-uk-icon="icon: behance; ratio: 1"></span> Behance</a></p></li>
                    <li className="no-margin"><p className="no-margin-bottom"><a className="text-gray-extra-dark" href="#"><span className="youtube position-relative bottom-1 margin-right-10px" data-uk-icon="icon: youtube; ratio: 1"></span> Youtube</a></p></li>
                
                </ul>
                {/* End Links */}
                
            </div>
            {/* End Column */}
            
        
            {/* Column */}
            <div className="col-lg-3 col-md-6 col-sm-12 col-xs-12 center-col sm-text-center">
            
            
                <p className="text-weight-600 poppins text-extra-large text-gray-extra-dark">Contactos.</p>

                {/* Text */}
                
                
                {/* Links */}
                <ul className="list-unstyled no-margin">
                
                    <li className="no-margin"><p className="text-gray-dark"><span className="icon-gold position-relative bottom-1 margin-right-10px" data-uk-icon="icon: location; ratio: 1"></span>Estrada direita da Petrangol
junto a Paragem da Cimangola,
Frente a unidade das FAA 08</p></li>
                    
                    <li className="no-margin"><p><a className="text-gray-extra-dark" href="#"><span className="icon-gold position-relative bottom-1 margin-right-10px" data-uk-icon="icon: mail; ratio: 1"></span>geral@graﬁcacnap.com</a></p></li>
                                        
                    <li className="no-margin"><p><a className="text-gray-extra-dark" href="#"><span className="icon-gold position-relative bottom-1 margin-right-10px" data-uk-icon="icon: receiver; ratio: 1"></span>+244 922 702 003</a></p></li>
                    
                    <li className="no-margin"><p className="no-margin-bottom"><a className="text-gray-extra-dark" href="#"><span className="icon-gold position-relative bottom-1 margin-right-10px" data-uk-icon="icon: file-text; ratio: 1"></span>graﬁcacnap.com</a></p></li>
                    
                </ul>
                {/* End Links */}
                
            </div>
            {/* End Column */}
            
        </div>
        {/* End Row */}

    </footer>
        </>
    )
}
