import React from 'react'

export const Header = () => {
    return (
        <>


           

		{/* Return to top icon */}
		<a href="javascript:" id="return-to-top"><h6 className="text-weight-700 margin-bottom-10px"><span className="text-gray-extra-dark bg-gold padding-left-10px padding-right-10px">Para o topo</span></h6></a>


		
		{/* Home */}
		<div className="uk-position-relative uk-visible-toggle" data-uk-slider="finite: true">

			{/* Slider Container */}
			<ul className="uk-slider-items uk-child-width-1-4@m uk-grid uk-grid-small" data-uk-height-viewport>

			
				<li className="uk-transition-toggle uk-animation-toggle overflow-hidden">
					
					{/* Slider Image */}
					<img src="images/backgrounds/bg-01.jpg" alt="" data-uk-cover />
	
					{/* Overlay */}
					<div className="uk-position-cover">
					
						{/* Overlay Content */}
						<div className="uk-position-bottom text-left padding-50px">
		
							{/* Titles */}
							<h6 className="text-weight-700 margin-top-10px margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">Impressão Digital </span></a></h6>
							<h6 className="text-weight-700 margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">e Offset</span></a></h6>
							{/* End Titles */}
							
							{/* Buttons */}
							<a className="btn btn-small btn-white sm-display-table no-margin-bottom" href="#">Saber mais.</a>
						
						</div>
						{/* Overlay Content */}
						
					</div>
					{/* End Overlay */}
					
				</li>


				<li className="uk-transition-toggle uk-animation-toggle overflow-hidden">
					
					{/* Slider Image */}
					<img src="images/backgrounds/bg-02.jpg" alt="" data-uk-cover />
	
					{/* Overlay */}
					<div className="uk-position-cover">
					
						{/* Overlay Content */}
						<div className="uk-position-bottom text-left padding-50px">
						

							{/* Titles */}
							<h6 className="text-weight-700 margin-top-10px margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">Personalização de Brindes </span></a></h6>
							<h6 className="text-weight-700 margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">e Produtos.</span></a></h6>
							{/* End Titles */}
							
							{/* Buttons */}
							<a className="btn btn-small btn-white sm-display-table no-margin-bottom" href="#">Saber mais.</a>
						
						</div>
						{/* Overlay Content */}
						
					</div>
					{/* End Overlay */}
					
				</li>
				
				
				<li className="uk-transition-toggle uk-animation-toggle overflow-hidden">
					
					{/* Slider Image */}
					<img src="images/backgrounds/bg-03.jpg" alt="" data-uk-cover />
	
					{/* Overlay */}
					<div className="uk-position-cover">
					
						{/* Overlay Content */}
						<div className="uk-position-bottom text-left padding-50px">

							{/* Titles */}
							<h6 className="text-weight-700 margin-top-10px margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">Serviços </span></a></h6>
							<h6 className="text-weight-700 margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">de Bordados</span></a></h6>
							{/* End Titles */}
							
							{/* Buttons */}
							<a className="btn btn-small btn-white sm-display-table no-margin-bottom" href="#">Saber mais.</a>
						
						</div>
						{/* Overlay Content */}
						
					</div>
					{/* End Overlay */}
					
				</li>
				
				
				<li className="uk-transition-toggle uk-animation-toggle overflow-hidden">
					
					{/* Slider Image */}
					<img src="images/backgrounds/bg-13.jpg" alt="" data-uk-cover />
	
					{/* Overlay */}
					<div className="uk-position-cover">
					
						{/* Overlay Content */}
						<div className="uk-position-bottom text-left padding-50px">
		
							{/* Titles */}
							<h6 className="text-weight-700 margin-top-10px margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">Serviços</span></a></h6>
							<h6 className="text-weight-700 margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">de Serigrafia.</span></a></h6>
							{/* End Titles */}
							
							{/* Buttons */}
							<a className="btn btn-small btn-white sm-display-table no-margin-bottom" href="#">Saber mais.</a>
						
						</div>
						{/* Overlay Content */}
						
					</div>
					{/* End Overlay */}
					
				</li>
				
				
				<li className="uk-transition-toggle uk-animation-toggle overflow-hidden">
					
					{/* Slider Image */}
					<img src="images/backgrounds/bg-04.jpg" alt="" data-uk-cover />
	
					{/* Overlay */}
					<div className="uk-position-cover">
					
						{/* Overlay Content */}
						<div className="uk-position-bottom text-left padding-50px">

							{/* Titles */}
							<h6 className="text-weight-700 margin-top-10px margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">Impressão</span></a></h6>
							<h6 className="text-weight-700 margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">em DTF e UV.</span></a></h6>
							{/* End Titles */}
							
							{/* Buttons */}
							<a className="btn btn-small btn-white sm-display-table no-margin-bottom" href="#">Saber mais.</a>
						
						</div>
						{/* Overlay Content */}
						
					</div>
					{/* End Overlay */}
					
				</li>
				
				<li className="uk-transition-toggle uk-animation-toggle overflow-hidden">
					
					{/* Slider Image */}
					<img src="images/backgrounds/bg-04.jpg" alt="" data-uk-cover />
	
					{/* Overlay */}
					<div className="uk-position-cover">
					
						{/* Overlay Content */}
						<div className="uk-position-bottom text-left padding-50px">

							{/* Titles */}
							<h6 className="text-weight-700 margin-top-10px margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">Sublimação</span></a></h6>
							<h6 className="text-weight-700 margin-bottom-10px"><a href="#"><span className="text-gray-extra-dark bg-white padding-left-10px padding-right-10px">e Transferência.</span></a></h6>
							{/* End Titles */}
							
							{/* Buttons */}
							<a className="btn btn-small btn-white sm-display-table no-margin-bottom" href="#">Saber mais.</a>
						
						</div>
						{/* Overlay Content */}
						
					</div>
					{/* End Overlay */}
					
				</li>
				
				
				
			</ul>

		</div>
		{/* End Home */}

		
		{/* Header Light */}
		<div className="z-index-999" data-uk-sticky>
		
			{/* Header Options */}
			<div className="navbar-white">

				{/* Header Container */}
				<nav className="uk-navbar-container" data-uk-navbar="boundary-align: true; align: center;">
				
					{/* Navigation Left Side */}
					<div className="uk-navbar-left padding-left-four-percent">
					
						{/* Logo Image */}
						<a className="uk-navbar-item uk-logo text-gray-extra-dark text-weight-700" href="#" data-uk-scroll>
                            <img src='images/logo.png'/>
                        </a>
					
					</div>
					{/* End Navigation Left Side */}
					
					
					
					{/* Navigation Right Side */}
					<div className="uk-navbar-right uk-dark padding-right-four-percent">

						{/* Links */}
						<ul className="uk-navbar-nav text-weight-600">
							
							
							{/* Link */}
							<li>
							
								<a className="uk-visible@l" href="#" data-uk-scroll><span className="text-gray-regular text-weight-400 text-medium roboto">Inicio</span></a>

							</li>
							
							
							{/* Link */}
							<li>
							
								<a className="uk-visible@l" href="#about" data-uk-scroll><span className="text-gray-regular text-weight-400 text-medium roboto">Sobre nós</span></a>

							</li>
							
							
							
							{/* Link */}
							<li>
							
								<a className="uk-visible@l" href="#portfolio" data-uk-scroll><span className="text-gray-regular text-weight-400 text-medium roboto">Portfolio</span></a>

							</li>
							

							{/* Link */}
							<li>
							
								<a className="uk-visible@l" href="#shop" data-uk-scroll><span className="text-gray-regular text-weight-400 text-medium roboto">Shop</span></a>

							</li>
							
							
							{/* Link */}
							<li>
							
								<a className="uk-visible@l" href="#blog" data-uk-scroll><span className="text-gray-regular text-weight-400 text-medium roboto">Blog</span></a>
								
							</li>
					
							
							{/* Link */}
							<li>
							
								<a className="uk-visible@l" href="#contact" data-uk-scroll><span className="text-gray-regular text-weight-400 text-medium roboto">Contact</span></a>

							</li>
							
					
							{/* Off-Canvas Navigation Toggle */}
							<li>
							
								<div className="uk-navbar-right uk-hidden@l">
								
									<a className="uk-navbar-toggle" data-uk-navbar-toggle-icon data-uk-toggle="target: #offcanvas-nav"></a>
									
								</div>
							
							</li>
							{/* End Off-Canvas Navigation Toggle */}
							
							<li>
							
								<a className="uk-visible@l" href="#"><span className="behance" data-uk-icon="icon: behance; ratio: 1"></span></a>

							</li>
							
							<li>
							
								<a className="uk-visible@l" href="#"><span className="pinterest" data-uk-icon="icon: pinterest; ratio: 1"></span></a>

							</li>
							
							<li>
							
								<a className="uk-visible@l" href="#"><span className="instagram" data-uk-icon="icon: instagram; ratio: 1"></span></a>

							</li>
							
							
							<li>
							
								<a className="uk-visible@l" href="#"><span className="facebook" data-uk-icon="icon: facebook; ratio: 1"></span></a>

							</li>
							
							
							
							<li>
							
								<a className="uk-navbar-toggle text-black" href="#modal-full4" data-uk-search-icon data-uk-toggle></a>
								
								{/* Search Modal */}
								<div id="modal-full4" className="uk-modal-full uk-modal" data-uk-modal>
								
									<div className="bg-black uk-modal-dialog uk-flex uk-flex-center uk-flex-middle" data-uk-height-viewport>
									
										<button className="bg-black uk-modal-close-full" type="button" data-uk-close></button>
										
										{/* Input Form */}
										<form className="uk-search width-100 uk-search-large">
										
											<input className="uk-search-input text-uppercase text-weight-500 text-center letter-spacing-1" type="search" placeholder="Search"/>
										
										</form>
										{/* End Input Form */}
										
									</div>
									
								</div>
								{/* End Search Modal */}
							
							</li>
							

							
							
						</ul>
						{/* End Links */}
						
					</div>
					{/* End Navigation Right Side */}
					
				</nav>
				{/* End Header Container */}
				
			</div>
			{/* End Header Options */}
		
		</div>
		{/* End Header Light */}
		
		
		{/* Off-Canvas Navigation */}
		<div id="offcanvas-nav" data-uk-offcanvas="mode: push; overlay: true; esc-close: true;">
		
			{/* Off-Canvas Bar */}
			<div className="uk-offcanvas-bar menu-dark bg-black uk-flex uk-flex-middle">
				
				<div>

					{/* Off-Canvas Links*/}
					<ul className="uk-nav-default uk-nav-parent-icon text-left" data-uk-nav>
					
						
						{/* Link */}
						<li className="margin-bottom-10px">
						
							<a href="#"><span className="text-gray-light text-weight-700 text-extra-large roboto">Nave.</span></a>

						</li>
						{/* End Link */}

						{/* Link */}
						<li className="margin-bottom-10px">
						
							<a href="#"><span className="text-gray-light text-weight-400 text-medium roboto">Inicio</span></a>

						</li>
						{/* End Link */}
						
						
						{/* Link */}
						<li className="margin-bottom-10px">
						
							<a href="#about" data-uk-scroll><span className="text-gray-light text-weight-400 text-medium roboto">Sobre nós</span></a>

						</li>
						{/* End Link */}
						
						{/* Link */}
						<li className="uk-parent margin-bottom-10px">
						
							<a href="#about" data-uk-scroll><span className="text-gray-light text-weight-400 text-medium roboto">Portfolio</span></a>
							
							{/* End Dropdown */}
							
						</li>
						{/* End Link */}
						
						
						{/* Link */}
						<li className="uk-parent margin-bottom-10px">
						
							<a href="#"><span className="text-gray-light text-weight-400 text-medium roboto">Pages</span></a>
							
							{/* Dropdown */}
							<ul className="uk-nav-sub">

								<li><a href="page-portfolio-about.html">About</a></li>
								<li><a href="page-portfolio-services.html">Services</a></li>
								<li><a href="page-portfolio-pricing.html">Pricing</a></li>
								<li><a href="page-portfolio-contact.html">Contact</a></li>
								
							</ul>
							{/* End Dropdown */}
							
						</li>
						{/* End Link */}

						
						{/* Link */}
						<li className="uk-parent margin-bottom-10px">
						
							<a href="#"><span className="text-gray-light text-weight-400 text-medium roboto">Blog</span></a>
							
							{/* Dropdown */}
							<ul className="uk-nav-sub">

								<li><a href="blog-grid.html">Blog Grid</a></li>
								<li><a href="blog-post.html">Blog Post</a></li>
								<li><a href="#">Blog Post Right Sidebar</a></li>
								
							</ul>
							{/* End Dropdown */}
							
						</li>
						{/* End Link */}
						
						
						
						{/* Link */}
						<li className="margin-bottom-10px">
						
							<a href="#service" data-uk-scroll><span className="text-gray-light text-weight-400 text-medium roboto">Services</span></a>

						</li>
						{/* End Link */}
						
						
						{/* Link */}
						<li className="margin-bottom-10px">
						
							<a href="#contact" data-uk-scroll><span className="text-gray-light text-weight-400 text-medium roboto">Contact</span></a>

						</li>
						{/* End Link */}

					</ul>
					{/* End Off-Canvas Links */}
					
					
					
					{/* Navigation Footer */}
					<div className="nav-footer margin-top-25px margin-bottom-50px">
				

						{/* Off-Canvas Search */}
						<form className="uk-search uk-search-default width-100 bg-black">
							<a href="#" className="uk-search-icon-flip text-white" data-uk-search-icon></a>
							<input className="uk-search-input text-medium text-white text-weight-400" type="search" placeholder="Search"/>
						</form>
						{/* End Off-Canvas Search */}

						{/* Social Links for Off-Canvas Footer */}
						<ul className="list-unstyled no-margin-bottom margin-top-25px">
						
							<li className="display-inline-block margin-right-25px"><a href="#"><i className="fab fa-facebook facebook"></i></a></li>
							<li className="display-inline-block margin-right-25px"><a href="#"><i className="fab fa-twitter twitter"></i></a></li>
							<li className="display-inline-block margin-right-25px"><a href="#"><i className="fab fa-instagram instagram"></i></a></li>
							<li className="display-inline-block"><a href="#"><i className="fab fa-behance behance"></i></a></li>

						</ul>
						{/* End Social Links for Off-Canvas Footer */}

						{/* Copyright Information */}
						<p className="no-margin-bottom margin-top-20px text-small text-gray-light text-weight-400">Copyright © 2024 of <a href="https://www.hiperbite.ao/" target="_blank">Hiperbite.ao</a></p>
						<p className="no-margin-bottom text-small text-gray-light text-weight-400 text-black">todos os direitos reservados.</p>
						{/* End Copyright Information */}
				
					</div>
					{/* End Navigation Footer */}

				</div>
			
			</div>
			{/* End Off-Canvas Bar */}
			
		</div>
		{/* End Off-Canvas Navigation */}
		

        </>
    )
}
